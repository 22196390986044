import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

function filterOutPing(automationType) {
  return automationType !== 'Ping'
}

function formatCommaSeparatedList(text) {
  let textList = text.split(',')
  const formattedTextList = textList.map((item) => {
    while (item.charAt(0) === ' ') {
      item = item.substring(1)
    }
    return item
  })
  return formattedTextList
}

function formatFreeTextList(textList) {
  let formattedTextList = ''
  if (textList.includes(',')) {
    let removedWhitespace = textList.replaceAll(' ', '')
    let splitItems = removedWhitespace.split(',')
    formattedTextList = [...new Set(splitItems)]
  } else if (textList.includes(' ')) {
    let splitItems = textList.split(' ')
    formattedTextList = [...new Set(splitItems)]
  } else if (textList.includes('\n')) {
    let splitItems = textList.split('\n')
    formattedTextList = [...new Set(splitItems)]
  } else {
    formattedTextList = [textList]
  }
  return formattedTextList
}

export function createCutsheets(
  cutsheetType,
  smartsheetId,
  teamName,
  selectedFiles,
) {
  return async function (dispatch) {
    var formData = new FormData()
    var endpoint = ''
    for (const key of Object.keys(selectedFiles)) {
      formData.append('files', selectedFiles[key])
      formData.append('smartsheet_id', smartsheetId)
    }
    if (cutsheetType === 'TVS') {
      formData.append('team_name', teamName)
      endpoint = 'tvs_supplemental_sheets'
    } else {
      formData.append('type', cutsheetType)
      endpoint = 'csv_cutsheets'
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    await axios
      .post(`${apiConfig.api.url}/${endpoint}`, formData, config)
      .then((resp) => {
        var result = {
          message: '',
          smartsheetData: [],
        }
        if (resp.data.id === 999) {
          result = {
            message: 'Could not find Smartsheet Template, please contact RDSE.',
          }
        } else if (resp.status === 201) {
          result = {
            message: `Smartsheet(s) created!`,
            smartsheetData: resp.data,
          }
        } else {
          result = {
            message: 'Failed to create Smartsheet, please retry.',
          }
        }
        dispatch({
          type: types.CREATE_SHEETS,
          payload: result,
        })
      })
      .catch((error) => {
        var result
        if (error.response.status === 400) {
          var badRequestMessage =
            'Invalid file type chosen for the selected cutsheet type.'
          if (
            error.response.data.message !== undefined &&
            error.response.data.message.includes(
              'Uploaded file has incorrect formatting',
            )
          ) {
            badRequestMessage = error.response.data.message
          }
          result = {
            message: badRequestMessage,
            smartsheetData: [],
          }
        } else {
          result = {
            message: 'Failed to create Smartsheet, please retry.',
            smartsheetData: [],
          }
        }
        dispatch({
          type: types.CREATE_SHEETS,
          payload: result,
        })
      })
  }
}

export function clearMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_MESSAGE,
      payload: '',
    })
  }
}

export function clearPortsMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_PORTS_MESSAGE,
      payload: '',
    })
  }
}
export function createPlatformDataSheet(sheetName) {
  const platformSheetData = { dataSheetName: sheetName }
  return async function (dispatch) {
    await axios
      .post(
        `${apiConfig.api.url}/generate_platform_data_sheets`,
        platformSheetData,
      )
      .then((resp) => {
        var result = {
          message: '',
          smartsheetData: [],
        }
        if (resp.status === 200) {
          result = {
            message: `Smartsheet created!`,
            smartsheetData: resp.data,
          }
        } else {
          result = {
            message: 'Failed to create platform data sheet, please retry.',
          }
        }
        dispatch({
          type: types.CREATE_SHEETS,
          payload: result,
        })
      })
      .catch((error) => {
        const result = {
          message: 'Failed to create platform data sheet, please retry.',
          smartsheetData: [],
        }
        dispatch({
          type: types.CREATE_SHEETS,
          payload: result,
        })
      })
  }
}

export function createScopeSheet(
  destinationFolder,
  sheetName,
  selectedAutomationTypes,
  additionalAutomationTypes,
  locations,
  teamName,
) {
  let allAutomations = selectedAutomationTypes
  if (additionalAutomationTypes !== '') {
    const formattedAutomationTypesList = formatCommaSeparatedList(
      additionalAutomationTypes,
    )
    allAutomations.push(...formattedAutomationTypesList)
  }
  const formattedLocationsList = formatFreeTextList(locations)

  const scopeSheetData = {
    sheet_name: sheetName,
    locations: formattedLocationsList,
    automation_types: allAutomations,
    folder_id: destinationFolder,
    team_name: teamName,
  }
  return async function (dispatch) {
    await axios
      .post(`${apiConfig.api.url}/create_scope_sheets`, scopeSheetData)
      .then((resp) => {
        var result = {
          message: '',
          smartsheetData: [],
        }
        if (resp.status === 200) {
          result = {
            message: `Smartsheet(s) created!`,
            smartsheetData: resp.data,
          }
        } else {
          result = {
            message: 'Failed to create scope sheet, please retry.',
            smartsheetData: [],
          }
        }
        dispatch({
          type: types.CREATE_SHEETS,
          payload: result,
        })
      })
      .catch((error) => {
        var result = {
          message: '',
          smartsheetData: [],
        }
        if (error.response.status === 404) {
          result = {
            message: `Smartsheet scope template could not be found for the selected team or is missing columns.`,
            smartsheetData: [],
          }
        } else {
          result = {
            message: 'Failed to create scope sheet, please retry.',
            smartsheetData: [],
          }
        }
        dispatch({
          type: types.CREATE_SHEETS,
          payload: result,
        })
      })
  }
}

export function getAutomationTypes() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/automation_types`)
      .then((resp) => {
        const result = {
          availableAutomationTypes: resp.data.filter(filterOutPing),
        }
        dispatch({
          type: types.GET_AUTOMATION_TYPES,
          payload: result,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Automation Types.'
          dispatch({
            type: types.GET_AUTOMATION_TYPES,
            payload: result,
          })
        }
      })
  }
}

export function createOpenPortSheet(locations) {
  let formattedLocationsList = formatFreeTextList(locations)
  const postData = { location_ids: formattedLocationsList }
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(`${apiConfig.api.url}/location_open_ports`, postData)
      .then((resp) => {
        var result = {
          message: '',
          portSheetData: '',
        }
        if (resp.status === 200) {
          result = {
            message: `Smartsheet created!`,
            portSheetData: resp.data,
          }
        } else {
          result = {
            message: 'Failed to create Smartsheet, please retry.',
          }
        }
        dispatch({
          type: types.CREATE_OPEN_PORT_SHEET,
          payload: result,
        })
      })
      .catch((error) => {
        const result = {
          message: 'Failed to create Smartsheet, please retry.',
          portSheetData: [],
        }
        dispatch({
          type: types.CREATE_OPEN_PORT_SHEET,
          payload: result,
        })
      })
  }
}

export function getWorkspaces() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/workspaces`)
      .then((resp) => {
        const result = {
          workspaces: resp.data,
        }
        dispatch({
          type: types.GET_WORKSPACES,
          payload: result,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Workspaces.'
          dispatch({
            type: types.GET_WORKSPACES,
            payload: result,
          })
        }
      })
  }
}

export function getWorkspaceFolders(workspaceId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/workspace_folders?workspace_id=${workspaceId}`)
      .then((resp) => {
        const result = {
          workspaceFolders: resp.data,
        }
        dispatch({
          type: types.GET_WORKSPACE_FOLDERS,
          payload: result,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Folders.'
          dispatch({
            type: types.GET_WORKSPACE_FOLDERS,
            payload: result,
          })
        }
      })
  }
}

export function getActiveSheets(workspaceId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/workspace_contents?workspace_id=${workspaceId}`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_ACTIVE_SHEETS,
          payload: resp.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets..'
          dispatch({
            type: types.GET_ACTIVE_SHEETS,
            payload: result,
          })
        }
      })
  }
}
